import React, { useEffect, useState } from 'react';
import './App.css'; // Ensure your App specific styles, if any, are imported
import { getSessionInfo } from './sessionUtils'; // Import the getSessionInfo function


function App() {
  const [sessionInfo, setSessionInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSessionInfo = async () => {
      try {
        const info = await getSessionInfo();
        setSessionInfo(info);
      } catch (error) {
        console.error('Failed to fetch session info:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSessionInfo();
  }, []);

  const handleLoginClick = () => {
    window.location.href = 'https://auth.ciam-poc.com/am/XUI/?realm=root&authIndexType=service&authIndexValue=Login-bet-web';
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome Member</h1>
        <button onClick={handleLoginClick}>Login</button>
        {loading ? (
          <p>Loading session info...</p>
        ) : sessionInfo ? (
          <pre>{JSON.stringify(sessionInfo, null, 2)}</pre>
        ) : (
          <p></p>
        )}
      </header>
    </div>
  );
}

export default App;
