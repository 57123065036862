// sessionUtils.js
import axios from 'axios';

export async function getSessionInfo() {
  try {
    const response = await axios.post(
      'https://auth.ciam-poc.com/am/json/realms/root/realms/root/sessions?_prettyPrint=true&_action=getsessioninfo',
      {},
      {
        withCredentials: true, // Include cookies in the request
        headers: {
          'Accept-API-Version': 'resource=4.0',
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching session info:', error);
    return null;
  }
}
